export const domain = "https://sun-lottery-timer-tlhr.onrender.com/";
export const rupees = "₹";
export const zubgback = "#F48901";

// export const baseUrl = "min.righttime.fun";
export const baseUrl = "https://webs.ferryinfotech.in";

export const endpoint = {
  newlogin: `${baseUrl}/api/newlogin`,
  register_candidate_mobile: `${baseUrl}/api/register_candidate`,
  register_candidate_email: `${baseUrl}/api/register_candidate_new`,
  game_history: `${baseUrl}/api/color_result`,
  bet_placed: `${baseUrl}/api/betnew_color`,
  my_history: `${baseUrl}/api/one_min_myhistory`,
  get_balance: `${baseUrl}/api/get_balance`,
  my_team_level: `${baseUrl}/api/my_team`,
  my_team_level_report_indevidual: `${baseUrl}/api/member_report`,
  bank: `${baseUrl}/api/bank`,
  update_bank_details: `${baseUrl}/api/update_bank_details_new`,
  update_upi_details: `${baseUrl}/api/update_upi_details`,
  fund_transfer_history: `${baseUrl}/api/view_fund_transfer`,
  get_user_intro_name: `${baseUrl}/api/get_intro_name`,
  insert_fund_transfer: `${baseUrl}/api/insert_fund_transfer`,
  view_salary_income: `${baseUrl}/api/view_salary_income`,
  get_token_price: `${baseUrl}/api/token_price`,
  get_token_price_in_dooler: `${baseUrl}/api/get_price_ico`,
  update_password: `${baseUrl}/api/update_password`,
  pin_password: `${baseUrl}/api/update_pin_password`,
  view_ico_purchaseing: `${baseUrl}/api/view_ico_purchaseing`,
  insert_ico_purchase: `${baseUrl}/api/insert_ico_purchase`,
  view_withdrwal_new_inr: `${baseUrl}/api/view_withdrwal_new_inr`,
  win_list_top: `${baseUrl}/api/win_list_top`,
  wallet_deposit_history: `${baseUrl}/api/wallet_deposit`,
  wallet_deposit: `${baseUrl}/api/wallet_deposit_insert`,
  wallet_withdrawl: `${baseUrl}/api/insert_withdrawal_request_inr_new`,
  withdrawl_status: `${baseUrl}/api/status`,
  // payment
  indian_insert_deposite: `${baseUrl}/api/indian_insert_deposite`,
  // trx apis
  trx_game_history: `${baseUrl}/api/trx_result`,
  trx_bet_placed: `${baseUrl}/api/Trx_bet`,
  trx_my_history: `${baseUrl}/api/trx_one_min_myhistory`,
  registration_bonus: `${baseUrl}/api/view_direct_referral_income`,
  view_bank_details: `${baseUrl}/api/view_bank_details_new`,
  view_upi_details: `${baseUrl}/api/view_upi_details`,
  token_launch: `${baseUrl}/api/token_launch`,
  info_promotion:`${baseUrl}/api/get_info`,
  team_info:`${baseUrl}/api/team_count`,
  team_report:`${baseUrl}/api/TeamReport`,
  profile_function:`${baseUrl}/api/profile`,
  team_data:`${baseUrl}/api/get_all`,
};
